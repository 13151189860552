<script setup lang="ts">
import { Social } from "@/types";
withDefaults(
	defineProps<{
		socials: Social[];
		entryPoint: string;
		locationTid: string | boolean | null;
		isSmall?: boolean;
		buttonSize?: "xl" | "lg" | "md" | "s" | "xs";
	}>(),
	{ buttonSize: "xl", locationTid: null }
);
const route = useRoute();
const storePromoOffer = () => {
	const { promoOffer } = route.query;
	if (promoOffer && !Array.isArray(promoOffer)) {
		localStorage.setItem("promoOffer", promoOffer);
	}
};
const handleClick = (name: string) => {
	storePromoOffer();

	dispatchGAEvent({
		event: "click_button",
		button_name: name.toLowerCase(),
		location: "registration_window"
	});
};
</script>

<template>
	<div class="row-social" :class="[!socials.length ? 'no-social' : undefined, { small: isSmall }]">
		<a
			v-for="social in socials"
			:key="social.id"
			:href="social.href"
			:class="['link']"
			:data-tid="locationTid && `${locationTid}-${social.name.toLowerCase()}-btn`"
			rel="nofollow"
			@click.stop="handleClick(social.name)"
		>
			<AButton :class="['social', `social-${social.id}`]" :size="buttonSize">
				<ASvg :name="`socials/${social.icon}${isSmall ? '-small' : ''}`" />
				<AText v-if="!isSmall" variant="toledo" :modifiers="['medium', 'capitalize']">{{ social.name }}</AText>
			</AButton>
		</a>
	</div>
</template>

<style scoped lang="scss">
.row-social {
	$self: &;
	display: flex;
	gap: gutter(1.5);
	padding-bottom: gutter(1);
	color: var(--conakry);
	width: 100%;
	justify-content: center;

	&:deep(.size-s) {
		height: 36px;
	}

	&.no-social {
		padding: 0;
	}

	a.link {
		display: flex;
		min-width: calc(50% - 8px);
	}

	&.small {
		padding: 0;
		justify-content: flex-start;
		display: none;

		@include media-breakpoint-up(md) {
			display: flex;
		}

		a.link {
			min-width: auto;
			width: 50px;
			height: 50px;
		}
	}
}

.social {
	border: none;
	transition: background 0.3s;
	width: 100%;
	padding: gutter(0.75) 0;

	@include media-breakpoint-down(lg) {
		width: 100%;
	}

	&:deep(svg) {
		transform: translateY(-1px);
		margin: 0 gutter(0.5) 0 0;
		font-size: 24px;
	}

	&.social-gp {
		background: var(--cannes);
		color: var(--calamba);

		&:hover {
			background-color: var(--citeureup);
		}
	}

	&.social-fb {
		background: var(--cartagena);
		color: var(--cannes);

		&:hover {
			background: var(--cartagena);
		}
	}

	.small & {
		display: flex;
		align-items: center;
		justify-content: center;
		background: var(--casablanca);
		padding: gutter(1.5);
		border-radius: 14px;

		&:hover {
			background: var(--casablanca);
		}

		&:deep(svg) {
			margin: 0;
		}
	}
}
</style>
